import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@material-ui/core';
import Alert from '@mui/material/Alert';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CircularProgress, {
  circularProgressClasses,
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

// Define the structure of your API response
interface Data {
  key: string | null;
  FullLink: string;
}

function Redirect() {
  const { param } = useParams<{ param: string }>(); // Specify the type of your URL parameters
  const navigate = useNavigate();
  const [data, setData] = useState<Data | null>(null); // Use the Data interface for your state
  const [loading, setLoading] = useState(true);

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
  }));
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`/api/${param}`);
        const data: Data = await response.json(); // TypeScript knows the shape of `data` here
        setData(data);
        setLoading(false);
        if (data.key !== null) {
           setTimeout(() => (
            window.location.href = data.FullLink), 3000);

        }
      } catch (error) {
        console.error("Failed to fetch data: ", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [param, navigate]);


    return (
      <Card>
      <CardActionArea>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
           
          </Typography>
         
            <Typography variant="body2" color="textSecondary" component="p">
              {data && data.key !== null ? (
                    <Alert severity="info">
              Redirecting to: {data?.FullLink}</Alert>) : (

              <Alert severity="error">Não encontramos o link id : {param} , favor verificar jundo ao suporte se o link continua valido.</Alert>

              )}
            </Typography>
           <Typography variant="body2" color="textSecondary" component="p">
        
          </Typography>
          
          
        </CardContent>
      </CardActionArea>
    </Card>
    );
  }


export default Redirect;
