import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import Redirect from './Redirect';

function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/:param" element={<Redirect />} />
    </Routes>
  </BrowserRouter>
  );
}

export default App;
